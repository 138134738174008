import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import MainLayout from '../../components/MainLayout/MainLayout';
import MarketingFileCard from '../../components/MarketingFileCard/MarketingFileCard';
import {
  getFullMarketingPublication,
  getMarketingFiles,
  getMarketingPublications,
  PublicationsResponse
} from '../../core/api/marketing';
import { FaIcons } from '../../core/constants';
import {
  MarketingFile,
  MarketingFullPublication,
  MarketingPublication
} from '../../core/types/marketing';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import useMarketingStore from '../../store/marketingFolders';
import styles from './styles.module.scss';

const PAGE_LIMIT = 10;

const MarketingPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'marketing' });
  const { folderId } = useParams();
  const { folders } = useMarketingStore();
  const [prevSearch, setPrevSearch] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const currentFolder = folders.find(({ id }) => Number(folderId) === id);
  const [files, setFiles] = useState<MarketingFile[]>([]);
  const [fullPublication, setFullPublication] =
    useState<MarketingFullPublication | null>(null);
  const [publications, setPublications] = useState<MarketingPublication[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isPublications = folderId === '0';

  const fetchFullPublication = async (slug: string) => {
    try {
      const fetchedPublication = await getFullMarketingPublication(slug);

      setFullPublication(fetchedPublication as MarketingFullPublication);
    } catch (error) {
      console.error(error);
      setFullPublication(null);
    }
  };

  const fetchPublications = async () => {
    setIsLoading(true);
    try {
      const response = await getMarketingPublications({
        search: searchValue,
        page: currentPage,
        pageLimit: PAGE_LIMIT
      });
      const fetchedPublications = (response as PublicationsResponse).results;

      if (currentPage > 1) {
        setPublications([...publications, ...fetchedPublications]);
      } else {
        setPublications(fetchedPublications);
        await fetchFullPublication(fetchedPublications[0].slug);
      }
      setTotalCount((response as PublicationsResponse).count);
    } catch (error) {
      console.error(error);
      setPublications([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFiles = async () => {
    try {
      const fetchedFiles = await getMarketingFiles(
        folderId as string,
        searchValue
      );
      setFiles(fetchedFiles as MarketingFile[]);
    } catch (error) {
      console.error(error);
      setFiles([]);
    }
  };
  const fetchMethod = isPublications ? fetchPublications : fetchFiles;

  const startSearch = useDebouncedCallback(() => {
    setPrevSearch(searchValue);
    setCurrentPage(1);
  }, 500);

  useEffect(() => {
    if (prevSearch.length || searchValue.length) startSearch();
  }, [searchValue]);

  useEffect(() => {
    fetchMethod();
  }, [prevSearch]);

  useEffect(() => {
    fetchMethod();
    setFullPublication(null);
  }, [folderId, currentPage]);

  const displayedItems = isPublications ? publications : files;
  const canShowMore = totalCount > displayedItems.length;

  return (
    <MainLayout>
      <main>
        <section className={styles.marketingSection}>
          <header
            className={classNames(styles.sectionHeader, {
              [styles.publicationsView]: isPublications
            })}
          >
            <h1 className={styles.sectionTitle}>{currentFolder?.title}</h1>
            {!isPublications && (
              <Input
                name="item search"
                placeholder={t('search-placeholder')}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                Icon={FaIcons.searchIcon}
                iconClassName={styles.searchIcon}
                inputClassName={styles.searchInput}
              />
            )}
            {!!fullPublication && (
              <div
                className={styles.embedWrapper}
                dangerouslySetInnerHTML={{
                  __html: fullPublication?.embed
                }}
              />
            )}
          </header>
          <main className={styles.sectionMain}>
            <div className={styles.marketingItems}>
              {displayedItems.map((file) => (
                <MarketingFileCard
                  key={
                    (file as MarketingFile).id ||
                    (file as MarketingPublication).slug
                  }
                  item={file}
                  selectPublication={() => {
                    fetchFullPublication((file as MarketingPublication).slug);
                    document.scrollingElement?.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    });
                  }}
                />
              ))}
            </div>
          </main>
          {canShowMore && (
            <Button
              view="secondary"
              buttonText={t('load-more-btn-text')}
              className={styles.loadMoreButton}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={isLoading}
            />
          )}
        </section>
      </main>
    </MainLayout>
  );
};

export default MarketingPage;
