import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  addNotification,
  editNotification
} from '../../core/api/notifications';
import { AvailableLocales } from '../../core/constants';
import { EditedNotification, FullNotification } from '../../core/types/user';
import Button from '../Button/Button';
import DateSelector from '../DateSelector/DateSelector';
import Input from '../Input/Input';
import LanguageToggle from '../LanguageToggle/LanguageToggle';
import styles from './NotificationForm.module.scss';

interface IProps {
  refetchNotifications: () => void;
  selectedNotification?: FullNotification | null;
  updateSelectedNotification?: (data: EditedNotification) => void;
  resetSelectedNotification?: () => void;
}

type NotificationLocale = {
  locale: AvailableLocales;
  title: string;
  notification: string;
};

type NotificationFields = {
  id?: number;
  date: Date;
  translations: NotificationLocale[];
};

const EMPTY_TRANSLATIONS = [
  // {
  //   locale: AvailableLocales.EN,
  //   title: '',
  //   notification: ''
  // },
  {
    locale: AvailableLocales.NL,
    title: '',
    notification: ''
  },
  {
    locale: AvailableLocales.FR,
    title: '',
    notification: ''
  }
];

const EMPTY_NOTIFICATION = {
  date: new Date(),
  translations: EMPTY_TRANSLATIONS
};

const INIT_DATE_SELECT_PROPS = {
  date: new Date(),
  show: false
};

const NotificationForm = ({
  refetchNotifications,
  selectedNotification,
  updateSelectedNotification,
  resetSelectedNotification
}: IProps) => {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'notifications.form'
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<AvailableLocales>(
    (i18n.language as AvailableLocales) || AvailableLocales.NL
  );
  const [dateSelectProps, setDateSelectProps] = useState<{
    date: Date;
    show: boolean;
  }>(INIT_DATE_SELECT_PROPS);
  const formRef = useRef<FormikProps<NotificationFields> | null>(null);

  const handleSubmit = async (values: NotificationFields) => {
    setIsLoading(true);

    try {
      const tz = moment(values.date).format('ZZ');
      const hoursOffset = Number(tz) / 100;
      const requestBody = {
        notificationDate: moment(values.date)
          .add(hoursOffset, 'hours')
          .toDate(),
        translations: values.translations.reduce(
          (result, currentTranslation) => ({
            ...result,
            [currentTranslation.locale]: currentTranslation
          }),
          {}
        )
      };

      if (values.id) {
        const editedNotification = await editNotification(
          values.id,
          requestBody
        );
        updateSelectedNotification?.(editedNotification as EditedNotification);
      } else {
        await addNotification(requestBody);
        refetchNotifications();
      }

      formRef.current?.resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedNotification) {
      formRef.current?.setValues({
        id: selectedNotification.id,
        date: new Date(selectedNotification.notificationDate),
        translations: Object.values(selectedNotification.translations)
      });
    }
  }, [selectedNotification]);

  return (
    <section className={styles.notificationFormSection}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={EMPTY_NOTIFICATION}
        innerRef={(form) => (formRef.current = form)}
      >
        {({ values, setFieldValue, resetForm }) => {
          const currentTranslation = values.translations.find(
            ({ locale }) => locale === currentLanguage
          );

          const handleChangeTranslationField = ({
            value,
            field
          }: {
            value: string;
            field: 'title' | 'notification';
          }) => {
            const translations = formRef.current?.values.translations;

            const updatedTranslationIndex = translations?.findIndex(
              ({ locale }) => locale === currentTranslation?.locale
            ) as number;

            const newTranslations = [...values.translations];
            newTranslations[updatedTranslationIndex][field] = value;

            setFieldValue('translations', newTranslations);
          };

          const handleCancel = () => {
            resetSelectedNotification?.();
            resetForm();
          };

          return (
            <Form className={styles.notificationForm}>
              <LanguageToggle
                handleChange={setCurrentLanguage}
                dropdownView={false}
                // disabledLocales={[AvailableLocales.EN]}
              />
              <div className={styles.formField}>
                <Input
                  value={moment(values.date).format('DD-MM-YYYY')}
                  className={styles.textInput}
                  onClick={() =>
                    setDateSelectProps({ ...dateSelectProps, show: true })
                  }
                  readOnly
                />
                {!!dateSelectProps.show && (
                  <div className={styles.dateSelect}>
                    <DateSelector
                      date={dateSelectProps.date}
                      handleChange={(date) => {
                        setDateSelectProps({
                          show: false,
                          date
                        });
                        console.log('handle date change', { date });
                        formRef.current?.setFieldValue('date', date);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className={styles.formField}>
                <Input
                  type="text"
                  value={currentTranslation?.title}
                  placeholder={t('title-placeholder')}
                  className={styles.textInput}
                  onChange={(e) => {
                    handleChangeTranslationField({
                      value: e.target.value,
                      field: 'title'
                    });
                  }}
                />
              </div>
              <div className={styles.formField}>
                <textarea
                  className={styles.textarea}
                  rows={10}
                  placeholder={t('text-placeholder')}
                  value={currentTranslation?.notification}
                  onChange={(e) => {
                    handleChangeTranslationField({
                      value: e.target.value,
                      field: 'notification'
                    });
                  }}
                />
              </div>
              <div className={styles.actions}>
                <Button
                  type="submit"
                  buttonText={t('submit-btn-text')}
                  view="green"
                  disabled={isLoading}
                />
                <Button
                  type="button"
                  buttonText={t('cancel-btn-text')}
                  view="transparent"
                  disabled={isLoading}
                  onClick={handleCancel}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </section>
  );
};

export default NotificationForm;
