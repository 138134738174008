import classNames from 'classnames';
import { useMemo } from 'react';

import {
  FaIcons,
  ProductLabelsViews,
  ProductLabelTypes
} from '../../core/constants';
import { Product } from '../../core/types/product';
import ProductLabel from '../ProductLabel/ProductLabel';
import styles from './styles.module.scss';

const Labels: {
  [key: string]: { view: ProductLabelsViews; icon: FaIcons; textKey: string };
} = {
  [ProductLabelTypes.new]: {
    icon: FaIcons.starIcon,
    view: ProductLabelsViews.yellow,
    textKey: 'product-labels.new'
  },
  [ProductLabelTypes.promo]: {
    icon: FaIcons.boltIcon,
    view: ProductLabelsViews.red,
    textKey: 'product-labels.promo'
  },
  [ProductLabelTypes.freezer]: {
    icon: FaIcons.snowflakeIcon,
    view: ProductLabelsViews.blue,
    textKey: 'product-labels.freezer'
  },
  [ProductLabelTypes.classic]: {
    icon: FaIcons.forkKnifeIcon,
    view: ProductLabelsViews.grey,
    textKey: 'product-labels.classic'
  },
  [ProductLabelTypes.gold]: {
    icon: FaIcons.forkKnifeIcon,
    view: ProductLabelsViews.darkGolden,
    textKey: 'product-labels.gold'
  },
  [ProductLabelTypes.private]: {
    icon: FaIcons.tagIcon,
    view: ProductLabelsViews.yellowGreen,
    textKey: 'product-labels.private'
  }
};

const ProductLabels = ({
  product,
  className,
  view
}: {
  product: Product;
  className?: string;
  view: 'column' | 'row';
}) => {
  const labels = useMemo(() => {
    const resultArray = [];
    if (product.isNew) resultArray.push(Labels[ProductLabelTypes.new]);
    if (product.isPromo) resultArray.push(Labels[ProductLabelTypes.promo]);
    if (product.isPrivate) resultArray.push(Labels[ProductLabelTypes.private]);
    if (product.isFrozen) resultArray.push(Labels[ProductLabelTypes.freezer]);

    return resultArray;
  }, [product]);

  return (
    <span className={classNames(styles.productLabels, styles[view], className)}>
      {labels.map(({ icon, view, textKey }) => (
        <ProductLabel key={textKey} view={view} icon={icon} text={textKey} />
      ))}
    </span>
  );
};

export default ProductLabels;
