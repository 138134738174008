import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCategories } from '../../../core/api/categories';
import { FaIcons } from '../../../core/constants';
import {
  Categories,
  Category,
  FilterCategory
} from '../../../core/types/category';
import useClickOutside from '../../../hooks/useClickOutside';
import useProductsStore from '../../../store/products';
import FaIcon from '../../FaIcon';
import CategoriesList from './CategoriesList';
import styles from './styles.module.scss';

const CategoryFilter = () => {
  const { t } = useTranslation('translation');
  const {
    changeFilters,
    filters: { categories: savedCategories }
  } = useProductsStore();
  const [categories, setCategories] = useState<Categories>({});
  const [displayedCategories, setDisplayedCategories] = useState<Categories>(
    {}
  );
  const [selectedCategories, setSelectedCategories] = useState<
    FilterCategory[]
  >([]);
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
  const ref = useClickOutside(() => {
    setIsSearchActive(false);
  });

  const resetAllCategories = () => {
    const updatedCategories = { ...categories };
    const updatedDisplayedCategories = { ...displayedCategories };

    selectedCategories.forEach((currentCategory) => {
      const categoryIndex = updatedCategories[
        currentCategory?.parent as string
      ].children.findIndex(({ code }) => code === currentCategory.code);
      const displayedCategoryIndex = updatedDisplayedCategories[
        currentCategory?.parent as string
      ].children.findIndex(({ code }) => code === currentCategory.code);

      updatedCategories[currentCategory.parent as string].children[
        categoryIndex
      ].isSelected = false;
      updatedDisplayedCategories[currentCategory.parent as string].children[
        displayedCategoryIndex
      ].isSelected = false;
    });

    setCategories(updatedCategories);
    setDisplayedCategories(updatedDisplayedCategories);
    setSelectedCategories([]);
    changeFilters({ categories: [] });
  };

  const toggleCategory = (category: FilterCategory) => {
    const isSelected = !!savedCategories.find(
      (savedCode) => savedCode === category.code
    );

    const categoryGroup = displayedCategories[category.parent as string];
    const updatedChildren = categoryGroup.children.reduce(
      (result, currentCategory) => {
        if (currentCategory.code === category.code)
          return [
            ...result,
            { ...currentCategory, isSelected: !currentCategory.isSelected }
          ];
        return [...result, currentCategory];
      },
      [] as FilterCategory[]
    );

    setDisplayedCategories({
      ...displayedCategories,
      [category.parent as string]: {
        ...categoryGroup,
        children: updatedChildren
      }
    });

    if (isSelected) {
      const newCategories = savedCategories.filter(
        (code) => code !== category.code
      );
      changeFilters({ categories: newCategories });
    } else {
      changeFilters({ categories: [...savedCategories, category.code] });
    }
  };

  const fetchCategories = async () => {
    try {
      const rawCategories = await getCategories();

      const formattedCategories = (rawCategories as Category[]).reduce(
        (result, currentCategory) => {
          if (!currentCategory.parent) {
            return {
              ...result,
              [currentCategory.code]: { parent: currentCategory, children: [] }
            };
          }
          return result;
        },
        {} as Categories
      );

      (rawCategories as Category[]).forEach((currentCategory) => {
        if (currentCategory.parent) {
          formattedCategories[currentCategory.parent].children.push({
            ...currentCategory,
            isSelected: false
          });
        }
      });

      setCategories(formattedCategories);
      setDisplayedCategories(formattedCategories);
    } catch (error) {
      console.error('categories fetching error', error);
    }
  };

  const searchCategory = (searchString: string) => {
    const newCategories = Object.keys(categories).reduce(
      (result, currentKey) => {
        const validCategories = categories[currentKey].children.filter(
          ({ name }) => name.toLowerCase().includes(searchString.toLowerCase())
        );

        if (validCategories.length)
          return {
            ...result,
            [currentKey]: {
              ...categories[currentKey],
              children: validCategories
            }
          };

        return result;
      },
      {} as Categories
    );

    setDisplayedCategories(newCategories);
  };

  useEffect(() => {
    if (selectedCategories.length || savedCategories.length) {
      const subCategories: FilterCategory[] = [];
      Object.values(categories).forEach(({ children }) =>
        subCategories.push(...children)
      );
      const selected = subCategories.filter(({ code }) =>
        savedCategories.find((savedCode) => savedCode === code)
      );

      setSelectedCategories(
        selected.map((category) => ({ ...category, isSelected: true }))
      );
    }
  }, [savedCategories.length, categories]);

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className={styles.categoryFilter} ref={ref}>
      <div className={styles.categoryInputWrapper}>
        <input
          className={styles.categoryInput}
          placeholder={t('filters.categories-placeholder')}
          onChange={(event) => searchCategory(event.target.value)}
          onClick={() => setIsSearchActive(!isSearchActive)}
        />
        <FaIcon
          faName={FaIcons.chevronDown}
          className={styles.categoryInputIcon}
        />
      </div>
      {!!selectedCategories.length && (
        <div className={styles.selectedCategories}>
          <button className={styles.resetButton} onClick={resetAllCategories}>
            Alle filters verwijderen
          </button>
          <CategoriesList
            subCategories={selectedCategories}
            toggleCategory={toggleCategory}
          />
        </div>
      )}
      {isSearchActive &&
        Object.values(displayedCategories).map((filterItem) => (
          <CategoriesList
            key={filterItem.parent.code}
            parentCategory={filterItem.parent}
            subCategories={filterItem.children}
            toggleCategory={toggleCategory}
          />
        ))}
    </div>
  );
};

export default CategoryFilter;
