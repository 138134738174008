import classNames from 'classnames';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button/Button';
import DateSelector from '../../components/DateSelector/DateSelector';
import FaIcon from '../../components/FaIcon';
import Input from '../../components/Input/Input';
import MainLayout from '../../components/MainLayout/MainLayout';
import ActionConfirmation from '../../components/Modals/ActionConfirmation/ActionConfirmation';
import OrderHeader from '../../components/OrderHeader/OrderHeader';
import Table from '../../components/Table/Table';
import { getReturnReasons, requestReturnItems } from '../../core/api/products';
import { FaIcons } from '../../core/constants';
import { Product, ReturnReason } from '../../core/types/product';
import useClickOutside from '../../hooks/useClickOutside';
import styles from './styles.module.scss';

type ItemForReturn = {
  // articleNumber: number;
  articleNumber: string;
  description: string;
  quantity: number;
  weight: number;
  lotNumber: number;
  bestBefore: string;
  returnReason: string;
  imagePath: string;
};

type DateRowRef = {
  articleNr: string;
  dateInput: HTMLInputElement;
};

const EMPTY_ROW: ItemForReturn = {
  // articleNumber: 0,
  articleNumber: '',
  description: '',
  quantity: 0,
  weight: 0,
  lotNumber: 0,
  bestBefore: moment().format('DD/MM/YYYY'),
  returnReason: '',
  imagePath: ''
};

const CELL_STYLES = {
  padding: '8px 4px !important'
};

const mediumCellWidth = '8%';
const wideCellWidth = '24%';
const smallCellWidth = '6%';

const smallCellStyles = {
  ...CELL_STYLES,
  '@media screen and (max-width: 1300px)': {
    width: '80px'
  }
};
const wideCellStyles = {
  ...CELL_STYLES,
  '@media screen and (max-width: 1300px)': {
    width: '350px'
  }
};
const mediumCellStyles = {
  ...CELL_STYLES,
  '@media screen and (max-width: 1300px)': {
    width: '110px'
  }
};

const INNIT_DATE_SELECT_PROPS = {
  show: false,
  x: 0,
  y: 0,
  screenWidth: window.outerWidth
};

const INNIT_REASON_SELECT_PROPS = {
  show: false,
  x: 0,
  y: 0,
  screenWidth: window.outerWidth,
  inputWidth: 50,
  articleNumber: ''
};

const ItemsReturnPage = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'items-return' });
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [dateSelectProps, setDateSelectProps] = useState(
    INNIT_DATE_SELECT_PROPS
  );
  const [reasonsDropdownProps, setReasonsDropdownProps] = useState(
    INNIT_REASON_SELECT_PROPS
  );
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [submittedItems, setSubmittedItems] = useState<ItemForReturn[]>([]);
  const [images, setImages] = useState<{ [key: string]: File }>({});
  const [returnReasons, setReturnReasons] = useState<ReturnReason[]>([]);
  const rowToRemoveRef = useRef<string>('');
  const formRef = useRef<FormikProps<{ items: ItemForReturn[] }> | null>(null);
  const dateSelectRef = useRef<DateRowRef | null>(null);
  const dateSelectWrapperRef = useClickOutside(() => {
    if (dateSelectProps.show) {
      setDateSelectProps(INNIT_DATE_SELECT_PROPS);
    }
  });
  const reasonsDropdownRef = useClickOutside(() => {
    if (reasonsDropdownProps.show) {
      setReasonsDropdownProps(INNIT_REASON_SELECT_PROPS);
    }
  });

  const fetchReturnReasons = async () => {
    try {
      const reasons = await getReturnReasons();

      setReturnReasons(reasons as ReturnReason[]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitReturn = async (items: ItemForReturn[]) => {
    try {
      const itemsForReturn = new FormData();

      items.forEach((item, index) => {
        itemsForReturn.append(
          `${index}[productArticleNumber]`,
          item.articleNumber.toString()
        );
        itemsForReturn.append(`${index}[quantity]`, item.quantity.toString());
        itemsForReturn.append(`${index}[weight]`, item.weight.toString());
        itemsForReturn.append(`${index}[lotNumber]`, item.lotNumber.toString());
        itemsForReturn.append(`${index}[bestBefore]`, item.bestBefore);
        itemsForReturn.append(`${index}[returnReason]`, item.returnReason);
        itemsForReturn.append(
          `${index}[imageFile]`,
          images[item.articleNumber]
        );
      });

      await requestReturnItems({ itemsForReturn });

      formRef?.current?.resetForm();
      setSubmittedItems(items);
      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFieldChange = ({
    fieldName,
    value,
    articleNr
  }: {
    fieldName:
      | 'quantity'
      | 'weight'
      | 'lotNumber'
      | 'bestBefore'
      | 'returnReason'
      | 'imagePath';
    value: number | string;
    articleNr: string;
  }) => {
    const items = formRef?.current?.values?.items;

    if (items?.length) {
      const currentItemIndex = items.findIndex(
        ({ articleNumber }) => articleNumber === articleNr
      );

      if (currentItemIndex !== -1) {
        const newItem = {
          ...items[currentItemIndex],
          [fieldName]: value
        };

        items.splice(currentItemIndex, 1, newItem);
        formRef.current?.setFieldValue('items', [...items]);
      }
    }
  };

  const requestRemove = (articleNr: string) => {
    setShowConfirmationModal(true);
    rowToRemoveRef.current = articleNr;
  };

  const handleItemRemove = () => {
    const articleNr = rowToRemoveRef.current;
    const items = formRef?.current?.values?.items;

    if (items?.length) {
      const newItems = items.filter(
        ({ articleNumber }) => articleNumber !== articleNr
      );

      formRef.current?.setFieldValue('items', [...newItems]);
    }
  };

  const handleChangeReason = (code: string) => {
    handleFieldChange({
      articleNr: reasonsDropdownProps.articleNumber,
      fieldName: 'returnReason',
      value: code
    });
    setReasonsDropdownProps(INNIT_REASON_SELECT_PROPS);
  };

  const imageChangeHandle = ({
    articleNr,
    file
  }: {
    articleNr: string;
    file: File | undefined;
  }) => {
    if (file) {
      setImages({ ...images, [articleNr]: file });
      handleFieldChange({
        articleNr,
        fieldName: 'imagePath',
        value: URL.createObjectURL(file)
      });
    }
  };

  const columns = [
    {
      name: t('table.article-number'),
      selector: (row: ItemForReturn) => row.articleNumber,
      width: mediumCellWidth,
      style: { ...mediumCellStyles, paddingLeft: '22px !important' }
    },
    {
      name: t('table.description'),
      selector: (row: ItemForReturn) => row.description,
      width: wideCellWidth,
      style: wideCellStyles
    },
    {
      name: `${t('table.quantity')}*`,
      cell: (row: ItemForReturn) =>
        isSubmitted ? (
          row.quantity
        ) : (
          <Input
            inputClassName={styles.tableInput}
            value={row.quantity}
            name="quantity"
            onChange={(e) =>
              handleFieldChange({
                articleNr: row.articleNumber,
                fieldName: 'quantity',
                value: e.target.value
              })
            }
            required
          />
        ),
      width: mediumCellWidth,
      style: mediumCellStyles
    },
    {
      name: t('table.weight'),
      cell: (row: ItemForReturn) =>
        isSubmitted ? (
          row.weight
        ) : (
          <Input
            inputClassName={styles.tableInput}
            value={row.weight}
            name="weight"
            onChange={(e) =>
              handleFieldChange({
                articleNr: row.articleNumber,
                fieldName: 'weight',
                value: e.target.value
              })
            }
          />
        ),
      width: mediumCellWidth,
      style: mediumCellStyles
    },
    {
      name: t('table.lot-number'),
      cell: (row: ItemForReturn) =>
        isSubmitted ? (
          row.lotNumber
        ) : (
          <Input
            inputClassName={styles.tableInput}
            value={row.lotNumber}
            name="lotNumber"
            onChange={(e) =>
              handleFieldChange({
                articleNr: row.articleNumber,
                fieldName: 'lotNumber',
                value: e.target.value
              })
            }
          />
        ),
      width: mediumCellWidth,
      style: mediumCellStyles
    },
    {
      name: `${t('table.best-before')}*`,
      cell: (row: ItemForReturn) =>
        isSubmitted ? (
          row.bestBefore
        ) : (
          <div className={styles.dateInputRow}>
            <Input
              inputClassName={styles.tableInput}
              value={row.bestBefore}
              name="bestBefore"
              onClick={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                e.stopPropagation();
                dateSelectRef.current = {
                  articleNr: row.articleNumber,
                  dateInput: e.currentTarget
                };
                setDateSelectProps({
                  show: true,
                  x: rect.x,
                  y: rect.y,
                  screenWidth: window.outerWidth
                });
              }}
              required
            />
          </div>
        ),
      width: mediumCellWidth,
      style: mediumCellStyles
    },
    {
      name: `${t('table.return-reason')}*`,
      cell: (row: ItemForReturn) => {
        const selectedReason = returnReasons.find(
          (reason) => reason.code === row.returnReason
        );
        const isActive =
          reasonsDropdownProps.show &&
          reasonsDropdownProps.articleNumber === row.articleNumber;

        return isSubmitted ? (
          row.returnReason
        ) : (
          <div className={styles.reasonsDropdown}>
            <Input
              wrapperClassName={styles.returnReasonWrapper}
              inputClassName={styles.tableInput}
              value={selectedReason?.description}
              name="returnReason"
              Icon={FaIcons.chevronDown}
              required
              iconClassName={classNames(styles.chevronIcon, {
                [styles.active]: isActive
              })}
            />
            <button
              type="button"
              className={styles.overflowButton}
              onClick={(e) => {
                if (isActive) {
                  setReasonsDropdownProps(INNIT_REASON_SELECT_PROPS);
                } else {
                  const rect = e.currentTarget.getBoundingClientRect();
                  e.stopPropagation();
                  setReasonsDropdownProps({
                    show: true,
                    x: rect.x,
                    y: rect.y + window.scrollY,
                    screenWidth: window.outerWidth,
                    inputWidth: rect.width,
                    articleNumber: row.articleNumber
                  });
                }
              }}
            />
          </div>
        );
      },
      width: wideCellWidth,
      style: wideCellStyles
    },
    {
      name: t('table.image'),
      cell: (row: ItemForReturn) =>
        isSubmitted ? (
          <img
            className={styles.rowImage}
            src={row.imagePath || 'images/img-placeholder.svg'}
          />
        ) : (
          <>
            <label
              className={styles.fileInputLabel}
              htmlFor={`${row.articleNumber}-image-input`}
            >
              {row.imagePath ? (
                <img src={row.imagePath} />
              ) : (
                <FaIcon faName={FaIcons.cameraIcon} />
              )}
            </label>
            <input
              type="file"
              id={`${row.articleNumber}-image-input`}
              className={styles.fileInput}
              onChange={(e) => {
                imageChangeHandle({
                  articleNr: row.articleNumber,
                  file: e?.target?.files?.[0]
                });
              }}
            />
          </>
        ),
      width: smallCellWidth,
      style: {
        '@media screen and (max-width: 1300px)': {
          width: '206px'
        },
        paddingLeft: '22px !important'
      }
    },
    {
      name: '',
      cell: (row: ItemForReturn) => (
        <Button
          view="transparent"
          className={styles.removeButton}
          onClick={() => requestRemove(row.articleNumber)}
        >
          <FaIcon faName={FaIcons.trashCanIcon} />
        </Button>
      ),
      width: smallCellWidth,
      style: {
        ...smallCellStyles,
        backgroundColor: 'white'
      }
    }
  ];

  const selectProduct = (product: Product) => {
    const newItem: ItemForReturn = {
      ...EMPTY_ROW,
      articleNumber: product.articleNr
      // description: product.description
    };
    const firstItem = formRef?.current?.values?.items?.[0];
    const isEmpty = !firstItem?.articleNumber;
    formRef.current?.setFieldValue('items', [
      ...(isEmpty ? [] : formRef.current.values.items),
      newItem
    ]);
  };

  useEffect(() => {
    fetchReturnReasons();
  }, []);

  const dateSelectX = dateSelectProps.x - 55;
  const dateSelectY = dateSelectProps.y - 33;

  const isSmallScreen = 350 + dateSelectProps.x > dateSelectProps.screenWidth;
  const dateSelectPosX = isSmallScreen ? { right: 5 } : { left: dateSelectX };

  const isMobileReasons =
    350 + Math.abs(reasonsDropdownProps.x) > reasonsDropdownProps.screenWidth;
  const reasonSelectX = isMobileReasons
    ? { right: 5 }
    : { left: reasonsDropdownProps.x };

  return (
    <MainLayout>
      <div className={styles.itemsReturnPage}>
        <OrderHeader
          title={t('title')}
          backButtonTitle={t('back-btn-title')}
          searchPlaceholder={t('search-placeholder')}
          selectItem={selectProduct}
          showSearch
          titleLink={{
            text: t('privacy-link-text'),
            url: 'https://docs.google.com/document/d/19lbmjge3TaaGz27i3v-FhueENMlHEze00BWtZbctHwo/edit?tab=t.0'
          }}
        />
        {isSubmitted && (
          <div className={styles.submitMessage}>
            <FaIcon faName={FaIcons.thumbUpIcon} />
            <span>{t('return-submit-message')}</span>
          </div>
        )}
        <Formik
          initialValues={{ items: [EMPTY_ROW] as ItemForReturn[] }}
          onSubmit={(values) => handleSubmitReturn(values.items)}
          innerRef={(form) => (formRef.current = form)}
        >
          {({ values: { items } }) => (
            <Form className={classNames(styles.returnForm)}>
              <section className={styles.returnFormContent}>
                {!!items.length && (
                  <Table<ItemForReturn>
                    columns={columns}
                    data={isSubmitted ? submittedItems : items}
                    customStyles={{
                      headCells: {
                        style: {
                          paddingLeft: 18,
                          '&:nth-child(8)': {
                            paddingLeft: 6
                          }
                        }
                      }
                    }}
                  />
                )}
              </section>

              {!isSubmitted && (
                <Button
                  type="submit"
                  view="green"
                  className={styles.submitButton}
                  disabled={!formRef.current?.values?.items?.length}
                >
                  <FaIcon faName={FaIcons.paperPlaneIcon} />{' '}
                  {t('submit-btn-title')}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </div>
      {showConfirmationModal && (
        <ActionConfirmation
          confirmationText={t('remove-confirmation-text')}
          confirmText={t('confirmation-yes')}
          cancelText={t('confirmation-no')}
          onClose={() => setShowConfirmationModal(false)}
          handleCancel={() => setShowConfirmationModal(false)}
          handleConfirm={handleItemRemove}
        />
      )}
      {reasonsDropdownProps.show && (
        <div
          ref={reasonsDropdownRef}
          className={styles.reasonsDropdown}
          style={{
            top: reasonsDropdownProps.y + 60,
            ...reasonSelectX,
            width: reasonsDropdownProps.inputWidth
          }}
        >
          <div className={styles.reasonItems}>
            {returnReasons.map((reason) => (
              <button
                type="button"
                key={reason.code}
                onClick={() => {
                  handleChangeReason(reason.code);
                }}
              >
                {reason.description}
              </button>
            ))}
          </div>
        </div>
      )}
      {dateSelectProps.show && (
        <div
          ref={dateSelectWrapperRef}
          className={styles.dateSelectWrapper}
          style={{
            top: dateSelectY,
            ...dateSelectPosX
          }}
        >
          <DateSelector
            className={styles.rowDateSelect}
            handleChange={(date) => {
              handleFieldChange({
                articleNr: dateSelectRef.current?.articleNr || '',
                fieldName: 'bestBefore',
                value: moment(date).format('DD/MM/yyyy')
              });
              setDateSelectProps(INNIT_DATE_SELECT_PROPS);
            }}
          />
        </div>
      )}
    </MainLayout>
  );
};

export default ItemsReturnPage;
