import i18n from '../../i18n.config';
import useCartStore from '../../store/shoppingCart';
import { ERRORS } from '../constants';
import apiClient from './client';

export const getActiveCart = async (): Promise<void | Error> => {
  const { setCart } = useCartStore.getState();
  const { statusCode, response } = await apiClient.get<any>(
    'api/carts/get-active-cart'
  );

  if (statusCode === 200) {
    setCart(response);
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

type AddItemRequest = {
  productNumber: string;
  count: number;
  discount: number;
};

export const addToCart = async (
  requestData: AddItemRequest
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post<any>('api/carts/add-item', {
    body: JSON.stringify(requestData)
  });

  if (statusCode === 200) {
    await getActiveCart();
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

type RemoveItemRequest = {
  productNumber: string;
  count: number;
};

export const removeFromCart = async (
  requestData: RemoveItemRequest
): Promise<void | Error> => {
  const { statusCode } = await apiClient.post<any>('api/carts/remove-item', {
    body: JSON.stringify(requestData)
  });

  if (statusCode === 200) {
    await getActiveCart();
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};

type SubmitCartRequest = {
  comment: string;
  deliveryDate: string;
};

type SubmitCartResponse = {
  order_id: string;
};

//TODO configure proper externalDocumentNo handling
export const submitCart = async (requestData: SubmitCartRequest) => {
  const { statusCode, response } = await apiClient.post<SubmitCartResponse>(
    'api/carts/confirm',
    {
      body: JSON.stringify({ ...requestData, externalDocumentNo: '000000' })
    }
  );

  if (statusCode === 200) {
    await getActiveCart();
    return response.order_id;
  } else {
    throw new Error(i18n.t(ERRORS.default));
  }
};
