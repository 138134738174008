import classNames from 'classnames';
import { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  addToMyAssortmentList,
  removeFromMyAssortmentList
} from '../../core/api/products';
import { FaIcons } from '../../core/constants';
import { Product } from '../../core/types/product';
import FaIcon from '../FaIcon';
import styles from './styles.module.scss';

const FavButton = ({
  product,
  className = ''
}: {
  product: Product;
  className?: string;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'customer-list' });
  const [isFavorite, setIsFavorite] = useState<boolean>(product.isInAssortment);
  const handleToggleFavorite = async (event: SyntheticEvent) => {
    event.stopPropagation();
    try {
      const toggleMethod = isFavorite
        ? removeFromMyAssortmentList
        : addToMyAssortmentList;

      await toggleMethod(product.articleNr.toString());

      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error(error);
    }
  };

  const favIcon = isFavorite
    ? FaIcons.fileHeartFilledIcon
    : FaIcons.fileHeartEmptyIcon;
  const tooltipText = isFavorite
    ? t('remove-from-customer-list')
    : t('add-to-customer-list');

  return (
    <button
      className={classNames(
        styles.favButton,
        {
          [styles.favorite]: isFavorite
        },
        className
      )}
      onClick={handleToggleFavorite}
    >
      <FaIcon faName={favIcon} />
      <span className={styles.tooltip}>{tooltipText}</span>
    </button>
  );
};

export default FavButton;
