import { create } from 'zustand';

import { MarketingFolder } from '../core/types/marketing';

export const GastronoomFolder = {
  id: 0,
  title: 'Ambacht'
};

interface MArketingStore {
  folders: MarketingFolder[];
  setFolders: (newFolders: MarketingFolder[]) => void;
}

const useMarketingStore = create<MArketingStore>((set) => ({
  folders: [],
  setFolders: (newFolders: MarketingFolder[]) =>
    set((state) => ({ ...state, folders: newFolders }))
}));

export default useMarketingStore;
